import "./App.css";

function App() {
  return (
    <div className="clearfix container">
      <div className="site-info">
        &copy; 2022 EnterEnter All Rights Reserved.
        <span id="icp_info">
          <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">
            沪ICP备2021016355号
          </a>
        </span>
        <br />
        <span id="busuanzi_container_site_uv">
          本站访客数<span id="busuanzi_value_site_uv"></span>人次 本站总访问量
          <span id="busuanzi_value_site_pv"></span>次
        </span>
      </div>
      <div className="site-credit">
        Theme by
        <a
          href="https://github.com/iTimeTraveler/hexo-theme-hipaper"
          target="_blank"
          rel="noreferrer"
        >
          hipaper
        </a>
      </div>
    </div>
  );
}

export default App;
